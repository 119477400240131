<script setup lang="ts">
import type { VehicleResponse, VehicleHistoryResponse } from '~/types/vehicle'
import type { VehicleInformation, VehiclePlateData, VehicleGenericInformations } from '~/types/interfaces';
import { useUserStore } from '~/store/useUserStore'
import { useStorage } from '@vueuse/core'
import { useAppStore } from "@/store/useAppStore";
import type { LinkedGenArt } from '~/store/interfaces/graphicStoreInterfaces';
import { useGraphicSearchStore } from '~/store/useGraphicSearchStore';

interface Props {
  isLabel?: boolean
}

const userStore = useUserStore();
const { addToast } = useAppToast()
const appStore = useAppStore()
const { links } = useAppConfig()
const route = useRoute()
const config = useRuntimeConfig()
const graphicSearchStore = useGraphicSearchStore();
const { isMultipleVehicleSelectOpen, vehiclesGenericData, vehiclesPlateData, searchVehicleFrom, isSearchVehicleOpen } = useDashboard()
const sessionQuote = useStorage("quote", {}, sessionStorage);
const vehicleInformations: Ref<VehicleInformation> = useCookie(config.public.cookies.vehicleInformation)
const vehiclePlateData: Ref<VehiclePlateData> = useCookie(config.public.cookies.vehiclePlateData)
const vehicleGenericData: Ref<VehicleGenericInformations> = useCookie(config.public.cookies.vehicleGenericInformations)
const linkedGenArtsCookies: Ref<LinkedGenArt[] | null> = useCookie(config.public.localStorage.linkedGenArts);

withDefaults(defineProps<Props>(), {
  isLabel: () => true
})

const plateNumber = ref("")
const plateError = ref("")
const isOpen = ref(false)
const dropdownlist = ref<ComponentPublicInstance | null>(null);
const plateHistoryResponse = ref<VehicleHistoryResponse>()


const getCarcode = async (plateNumber: string) => {
  try {
    if(!checkForm(plateNumber)) return
    plateError.value = ""

    const getCarByPlateResponse = await $fetch<VehicleResponse>(`/api/siv/getCarByPlate?immat=${plateNumber}&userEmail=${userStore.user.customerEmail}`)

    if(getCarByPlateResponse.success !== "1") throw new Error(`[${getCarByPlateResponse.err_code}] ${getCarByPlateResponse.msg}`)

    isOpen.value = false
    vehiclesGenericData.value = getCarByPlateResponse.carGenericDatas
    vehiclesPlateData.value = getCarByPlateResponse.carPlateDatas
    linkedGenArtsCookies.value = null
    graphicSearchStore.carGenArts = []
    graphicSearchStore.graphicsHtml = {}
    graphicSearchStore.graphicStep = 'vehicle'
    if(getCarByPlateResponse.carPlateDatas.length >= 2) { 
      isMultipleVehicleSelectOpen.value = true      
      return
    }
    
    selectVehicle(getCarByPlateResponse.carPlateDatas[0].KType, getCarByPlateResponse.carPlateDatas[0].numberPlate)
  } catch(err: any) {
    console.error(err)
    addToast("Une erreur est survenue lors de la recherche par plaque.", 'error')
  }
}

const checkForm = (plateNumber: string ) => {
  if(plateNumber === "") {
    plateError.value = "Veuillez renseigner une plaque d'immatriculation."
    return false
  }
  return true
}

const toggleDropdown = async() => {
  plateHistoryResponse.value = await $fetch<VehicleHistoryResponse>("/api/siv/platesHistory", { query : { top: 3, userEmail: userStore.user.customerEmail}})
  isOpen.value = !isOpen.value

}

const platesHistory = computed(() => plateHistoryResponse.value?.history.map(vehicle => vehicle.plateNum) ?? [])

const selectVehicle = async (kType: number, numberPlate: string) => {
    const singleVehicle = vehiclesPlateData.value.filter((vehicle) => vehicle.KType === kType && vehicle.numberPlate === numberPlate)
    const genericVehicle = vehiclesGenericData.value.filter((vehicle) => vehicle.KType === kType)

    appStore.infoCar.actualKType = kType.toString()
    appStore.infoCar.actualImmat = numberPlate
    appStore.infoCar.carGenericDatas = genericVehicle
    appStore.infoCar.carPlateDatas = singleVehicle
    appStore.infoCar.actualVin = singleVehicle[0].vin
    vehiclePlateData.value = singleVehicle[0]
    vehicleGenericData.value = genericVehicle[0]

    vehicleInformations.value = {
        actualKType: appStore.infoCar.actualKType,
        actualImmat: appStore.infoCar.actualImmat,
        actualVin: appStore.infoCar.actualVin,
        lastKType: "",
        lastImmat: ""
    }
    sessionQuote.value = null
    graphicSearchStore.$reset()
    
    if(route.path === links.devis) {
      isSearchVehicleOpen.value = false
      return
    }
    await navigateTo(links.catalog)      
}

onMounted(() => {
    useOnClickOutside(dropdownlist, () => isOpen.value = false)
})
</script>

<template>
    <section class="box-immat section-inside text-secondary-color">
      <h2 v-if="isLabel" class="text-base">Par plaque d’immatriculation</h2>
      <form id="numberPlateForm" class="flex gap1" @submit.prevent="getCarcode(plateNumber)">
        <div class="plateSearch flex" :class="{ inputError: plateError }">
          <button class="flags"><ElementsIcon icon="flag-icons-fr"/></button>    
          <ElementsInput id="plateNumber" :class="['input-plate-number', { inputError: plateError }]" v-model="plateNumber" placeholder="AA-123-AA"/>
          <div>
            <button class="button-history" @click="toggleDropdown()" type="button"><ElementsIcon class="iconSmall" icon="fa-clock-rotate-left"/></button>
            <ul v-if="isOpen && platesHistory.length" class="dropdown-menu-history no-list-style" ref="dropdownlist" >
              <li v-for="plate in platesHistory" :key="plate" @click="getCarcode(plate)" >{{ plate }}</li>
            </ul>
          </div>
        </div>
        <ElementsButtonLinear class="button-search-plate" preIcon="fa-solid-magnifying-glass" type="submit"/>
      </form>
      <span v-if="plateError" class="text-sx text-error w-400">{{ plateError }}</span>      
  </section>
</template>

<style scoped lang="scss">
.plateSearch {
  border: 1px var(--borderBtnWhite) solid;
  border-radius: var(--roundedBorder);
  width:100%;
}

.inputError {
  border-color: var(--default-error-color);
}

.flags {
  background-color: #ffffff;
  border: none;
  border-top-left-radius: var(--roundedBorder);
  border-bottom-left-radius: var(--roundedBorder);
  padding: 0 .7rem;  
}

.input-plate-number {
  width:100%;
}

.input-plate-number :deep(input) {
  border: none;
  border-left: 1px var(--borderBtnWhite) solid;
  border-right: 1px var(--borderBtnWhite) solid;
  border-radius: 0;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  height: 40px;
}

.input-plate-number.inputError :deep(input) {
  border-color: var(--default-error-color);
}

.input-plate-number :deep(input::placeholder) {
  font-family: var(--default-font-site);
  color: var(--neutral71);
  font-size: .9rem;
  font-weight: 500;
}

.dropdown-menu-history {
  list-style-type: none;
  border-radius: var(--roundedBorder);
  padding: 0;
  margin: 0;
  background-color: #fff;
  border: 1px solid var(--borderBtnWhite);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 9;
}

.dropdown-menu-history li {
  padding: .3rem 1rem;
  cursor: pointer;
  font-weight: 500;
  font-size: .9rem;
  line-height: 1rem;
  text-transform: uppercase;
}

.dropdown-menu-history li:hover {
  background-color: #f1f1f1;
}

.button-history {
  background-color: #ffffff;
  border: none;
  border-top-right-radius: var(--roundedBorder);
  border-bottom-right-radius: var(--roundedBorder);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 .7rem;
  height: 100%;
  & svg {
    color: var(--mainColor);
  }
}

.button-search-plate {
  padding: 0 1rem;
}

.button-search-plate :deep(.iconDefault) {
  transition: transform var(--animation-site-duration) linear;
}

.button-search-plate:hover :deep(.iconDefault) {
  transform: rotateZ(90deg)
}
</style>