<script setup lang="ts">
import { useUserStore } from '~/store/useUserStore';
import type { VehicleGenericInformations, VehicleInformation, VehiclePlateData } from '~/types/interfaces';
import type { Brand, BrandModel, VehicleBrandApiResponse, VehicleModelApiResponse, VehicleModelTypeApiResponse } from '~/types/vehicle';
import { useStorage } from '@vueuse/core'

const userStore = useUserStore()
const config = useRuntimeConfig()
const vehicleInformations = useCookie<VehicleInformation>(config.public.cookies.vehicleInformation)
const vehicleGenericInformations = useCookie<VehicleGenericInformations>(config.public.cookies.vehicleGenericInformations)
const vehiclePlateData = useCookie<VehiclePlateData>(config.public.cookies.vehiclePlateData)
const sessionQuote = useStorage("quote", {}, sessionStorage);
const { links } = useAppConfig();

interface Props {
  isLabel?: boolean
}

withDefaults(defineProps<Props>(), {
  isLabel: () => true
})

const brands = ref<Brand[]>([])
const brandModels = ref<BrandModel[]>([])
const linkageTargets = ref<VehicleGenericInformations[]>([])
const defaultLinkedTarget: VehicleGenericInformations = {
  fromCache: 0,
  cache_ref: 0,
  cache_date: '',
  groupKey: '',
  default_car: false,
  supplierId: 0,
  supplierName: '',
  KType: 0,
  linkageTargetId: -1,
  linkageTargetType: '',
  description: '',
  mfrId: 0,
  mfrName: '',
  mfrShortName: '',
  vehicleModelSeriesId: '',
  vehicleModelSeriesName: '',
  beginYearMonth: '',
  rmiTypeId: 0,
  axleBodyTypes: [],
  vehicleImages: [],
  kbaNumbers: [],
  driveTypeKey: '',
  driveType: '',
  bodyStyleKey: '',
  bodyStyle: '',
  valves: '',
  fuelMixtureFormationTypeKey: '',
  fuelMixtureFormationType: '',
  fuelTypeKey: '',
  fuelType: '',
  engineTypeKey: '',
  engineType: '',
  horsePowerFrom: '',
  horsePowerTo: '',
  kiloWattsFrom: '',
  kiloWattsTo: '',
  cylinders: '',
  capacityCC: '',
  capacityLiters: '',
  engines: [],
  vehiclesInOperation: []
}
const defaultBrandModel = {
  id: -1,
  name: "",
  count: 0
}
const defaultBrand: Brand = {
  count:0,
  id: -1,
  linkageTargetType: '',
  name: ''
}

const selectedBrand = ref<Brand>(defaultBrand)
const selectedBrandModel = ref<BrandModel>(defaultBrandModel)
const selectedLinkedTarget = ref<VehicleGenericInformations>(defaultLinkedTarget)

const getModelOfBrands = async () => {  
  if(!brands.value.length) {
    const vehicleResponse = await $fetch<VehicleBrandApiResponse>('/api/catalog/getVehicleByModel', { query: { level: 1, userEmail: userStore.user.customerEmail }})
    brands.value = vehicleResponse.responseData.mfrFacets.counts.sort((a, b) => a.name.localeCompare(b.name));
  }
}

const getModelsForBrand = async () => {
  const vehicleResponse = await $fetch<VehicleModelApiResponse>('/api/catalog/getVehicleByModel', { query: { level: 2, level1Id: selectedBrand.value.id, userEmail: userStore.user.customerEmail }})
  brandModels.value = vehicleResponse.responseData.vehicleModelSeriesFacets.counts.sort((a, b) => a.name.localeCompare(b.name));
}

const getTypesForModel = async () => {
  const vehicleResponse = await $fetch<VehicleModelTypeApiResponse>('/api/catalog/getVehicleByModel', { query: { level: 3, level1Id: selectedBrand.value.id, level2Id: selectedBrandModel.value.id, userEmail: userStore.user.customerEmail }})
    linkageTargets.value = vehicleResponse.responseData.linkageTargets.sort((a, b) => a.description.localeCompare(b.description));
}

const selectVehicle = async () => {
  vehicleInformations.value = {
      actualKType: selectedLinkedTarget.value.linkageTargetId.toString(),
      actualImmat: "",
      actualVin: "",
      lastKType: "",
      lastImmat: ""
    }
    vehiclePlateData.value = null
    vehicleGenericInformations.value = selectedLinkedTarget.value
    sessionQuote.value = null
    await navigateTo(links.catalog)
}
</script>

<template>
    <section class="box-immat section-inside text-secondary-color">
        <h2 v-if="isLabel" class="text-base">Par véhicule</h2>
        <form id="vehicleByModel" class="flex gap1" @submit.prevent="selectVehicle()">
            <div class="flex gap1 flexColumn flex1">
                <select class="form-select" name="brand" id="brandName" @click.stop="getModelOfBrands()" @change="getModelsForBrand()" v-model="selectedBrand">
                  <option :value="defaultBrand" disabled selected >Saisir la marque</option>
                  <option :value="brand" v-for="brand in brands" :key="brand.id" > {{ brand.name }}</option>
                </select>
                <select class="form-select" name="model" id="vehicleModel" :disabled="selectedBrand.id === -1" v-model="selectedBrandModel" @change="getTypesForModel()">
                    <option :value="defaultBrandModel" disabled selected>Saisir le modèle</option>
                    <option :value="model" v-for="model in brandModels" :key="model.id" > {{ model.name }}</option>
                </select>
                <select class="form-select" name="type" id="modelType" :disabled="selectedBrandModel.id === -1" v-model="selectedLinkedTarget">
                    <option :value="defaultLinkedTarget" disabled selected>Saisir le type</option>
                    <option :value="linkedTarget" v-for="linkedTarget in linkageTargets" :key="linkedTarget.linkageTargetId" > {{ linkedTarget.description }} {{ linkedTarget.driveType }}</option>
                </select>
            </div>
            <ElementsButtonLinear class="button-search-plate" preIcon="fa-solid-magnifying-glass" type="submit"/>
        </form>
    </section>
</template>